import React from "react";
import lax from "lax.js";

class About extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  render() {
    return (
      <section
        className='about-area-two ptb-120 bg-image '
        style={{ marginTop: "20px" }}
      >
        <div className='container'>
          <div className='row h-100 '>
            <h2 className='text-justify' style={{ marginBottom: "20px" }}>
              Something About Us
            </h2>

            <div className='col-lg-6' style={{ marginTop: "10px" }}>
              <div className='about-image'>
                <img
                  src='/images/testbanner.jpg'
                  className='about-img1'
                  alt='about'
                  style={{ width: "100%", borderRadius: "40px" }}
                />

                {/* <LaxButton /> */}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='about-content text-justify'>
                <span>
                  <b>Who We Are</b>
                </span>

                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>

                <p>
                  Marching Frogs is a global network of legal professionals who
                  specialize in the legal industry. Our platform is dedicated to
                  providing support, insights, and connections for individuals
                  involved in legal matters. We offer a space for legal
                  professionals to engage, connect, and promote business
                  opportunities, while also recognizing their contributions to
                  the profession. Our members come from a variety of
                  backgrounds, including law firms, corporations, universities,
                  and venture capitalists, all with a shared passion for the
                  practice of law.
                </p>

                {/* <div className="signature">
                                    <img src={require("../../assets/images/signature.png")} alt="about" />
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
