import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

const OtherFooter = () => {
  return (
    <footer className='footer-area'>
      <Box sx={{ px: "5%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-around",
          }}
        >
          <div className='col py-sm-3 py-lg-0'>
            <div
              className='single-footer-widget '
              style={{ textAlign: "center" }}
            >
              <h3>Contact Us</h3>

              <p
                className='location'
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: 0,
                }}
              >
                <img
                  src='images/location.png'
                  alt=''
                  style={{ height: "20px", marginTop: "5px" }}
                />
                <p style={{ fontsize: "11px" }}>
                  216-A, Gautam Nagar Second Floor Above RGM Library New Delhi -
                  110049{" "}
                </p>
              </p>
              <Box
                className='location'
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "flex-start",
                    whiteSpace: "noWrap",
                  },
                }}
              >
                <img src='images/phone.png' alt='' style={{ height: "20px" }} />
                <p style={{ whiteSpace: "noWrap", fontsize: "11px" }}>
                  +91 9162685202 , +1 7036650511
                </p>
              </Box>
            </div>
          </div>

          <div className='col py-sm-3 py-lg-0 '>
            <div
              className='single-footer-widget'
              style={{ textAlign: "center" }}
            >
              <h3>Quick Links</h3>
              <p>
                {" "}
                <Link to='/about-us' style={{ cursor: "pointer" }}>
                  {" "}
                  <p> Who We Are </p>
                </Link>
              </p>
              <p>
                {" "}
                <Link to='/about-us' style={{ cursor: "pointer" }}>
                  {" "}
                  <p>Founders & Leadership</p>
                </Link>
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/marching-frogs-events/about/",
                    "_blank"
                  )
                }
              >
                LinkedIn
              </p>
              <Link to='/contact'>
                <p>Contact</p>
              </Link>

              <div
                class='modal fade'
                id='ampleModalLong'
                tabindex='-1'
                role='dialog'
                aria-labelledby='exampleModalLongTitle'
                aria-hidden='true'
              >
                <div class='modal-dialog' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='exampleModalLongTitle'>
                        Privacy Policy
                      </h5>
                      <button
                        type='button'
                        class='close'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div
                      class='modal-body'
                      style={{
                        padding: "10px 30px",
                        paddingTop: "20px",
                        textAlign: "left",
                      }}
                    >
                      Registration Cancellation by Participant At "Marching
                      Frogs," our dedication to protecting and honoring your
                      privacy runs deep within the very core of our being. We
                      handle personal information about our cherished customers
                      and those who show interest in our offerings with the
                      utmost care and respect. Our commitment to safeguarding
                      your privacy is unwavering. This privacy policy serves as
                      our promise to you, detailing the information we process,
                      how it may impact you, and the rights you hold dear. The
                      heart of "Marching Frogs" beats with the rhythm of
                      transparency and honesty. We pledge to keep you informed,
                      to walk hand in hand with you on this journey of trust. As
                      we evolve and grow, so too does our privacy policy. With
                      every step forward, we will share our progress with you,
                      ensuring that you are kept in the light. Whenever changes
                      are made, we will not only notify you through our website
                      but will also reach out to you directly. Your well-being
                      and peace of mind are paramount to us. In times of
                      uncertainty or concern, know that our Data Protection
                      Officers stand ready to listen, to reassure, and to guide.
                      Contact them at info@marchingfrogsevents.com, and feel the
                      warmth and passion that drives us to protect what matters
                      most - you.
                    </div>
                    <div class='modal-footer'>
                      <button
                        type='button'
                        class='btn btn-secondary'
                        data-dismiss='modal'
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class='modal fade'
                id='exampleModalLong'
                tabindex='-1'
                role='dialog'
                aria-labelledby='exampleModalLongTitle'
                aria-hidden='true'
              >
                <div class='modal-dialog' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='exampleModalLongTitle'></h5>
                      <button
                        type='button'
                        class='close'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div
                      class='modal-body'
                      style={{
                        padding: "10px 30px",
                        paddingTop: "20px",
                        textAlign: "left",
                      }}
                    >
                      Agreement Overview This Agreement is between Marching
                      Frogs ("WE") and YOU. By using our services, you
                      acknowledge that you have read, understood, and agree to
                      be bound by the terms, and that you will comply with all
                      applicable laws and regulations. We encourage you to
                      review and comply with proprietary notices and copyright
                      information. Please note that there may be potential
                      errors in the information provided on the website. We
                      reserve the right to modify these terms without prior
                      notice. These terms and conditions are related to the
                      services provided by Marching Frogs. You are required to
                      review and understand these terms before utilizing our
                      services. By using our services, you acknowledge complete
                      understanding and acceptance of these terms and
                      conditions. We disclaim any reliance on statements outside
                      of what is expressly detailed in this Agreement. Linking
                      to Other Websites We do not make any representations
                      regarding other websites accessed through our website.
                      These websites are independent of us, and we have no
                      control over their contents, images, or any other
                      material. We are not liable for any information or
                      services provided by the owner of the linked website. Our
                      endorsement or responsibility for the linked website is
                      not implied. We do not assume liability for any losses,
                      damages, or claims arising from harmful elements on the
                      linked website. Visitors and users should exercise caution
                      when using the linked website. We disclaim any and all
                      liability for the contents, images, or materials on the
                      linked website. Copyrights Materials provided on this
                      website are protected by copyright. Unauthorized copying,
                      reproduction, distribution, republication, downloading,
                      display, posting, or transmission of materials is strictly
                      prohibited without written permission. Visitors and users
                      are permitted to download one copy of materials for
                      personal or internal business use only. We reserve the
                      right to revoke permission to download materials in case
                      of breach of terms. Replicating or mirroring any material
                      without express written consent is prohibited. Content
                      shared by the organization is subject to exclusive
                      copyright protection. Unauthorized use of materials may
                      infringe upon copyright, trademark, privacy, and
                      communication laws. All content and functionality on the
                      website are the exclusive property of the organization or
                      its licensors and are protected by Indian copyright law.
                      Trademarks Trademarks utilized and exhibited on websites
                      are both registered and unregistered trademarks of
                      Marching Frogs. Other trademarks not solely owned by us
                      might be the property of others. No license or privilege
                      is granted to use any trademark or intellectual property
                      on these websites. We vigorously protect our intellectual
                      property rights. Usage of "Marching Frogs" and other
                      trademarks is strictly prohibited without written consent.
                      Integration of trademarks as part of a link requires
                      pre-approval in written form. Use of Website Content User
                      Postings Users acknowledge and agree that the website
                      shall own and have the unrestricted right to use, publish,
                      distribute, and exploit any information that users post on
                      the website. Users waive any and all claims against the
                      website for alleged or actual infringements of privacy
                      rights, publicity rights, moral rights, rights of
                      attribution, or any other intellectual property rights in
                      connection with the use and publication of such
                      submissions posted on the website. Users grant the website
                      a perpetual, royalty-free, non-exclusive, sub-licensable,
                      unrestricted, unlimited, worldwide, and irrevocable right
                      and license to use, reproduce, modify, publish, translate,
                      prepare derivative works, distribute, perform, or display
                      such submissions. The website does not represent or
                      endorse and is not responsible for the accuracy or
                      reliability of any submissions displayed, uploaded, or
                      posted on any message board or otherwise distributed
                      through the website. The website expressly disclaims any
                      and all liability related to submissions, and any reliance
                      upon such submissions by visitors shall be at their sole
                      discretion, liability, and risk.
                      <span style={{ color: "black", marginTop: "10px" }}>
                        Our Data Protection Officers can be contacted at{" "}
                      </span>
                    </div>
                    <div class='modal-footer'>
                      <button
                        type='button'
                        class='btn btn-secondary'
                        data-dismiss='modal'
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class='modal fade'
                id='termsConditions'
                tabindex='-1'
                role='dialog'
                aria-labelledby='exampleModalLongTitle'
                aria-hidden='true'
              >
                <div class='modal-dialog' role='document'>
                  <div class='modal-content'>
                    <div class='modal-header'>
                      <h5 class='modal-title' id='exampleModalLongTitle'>
                        Terms and Conditions
                      </h5>
                      <button
                        type='button'
                        class='close'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                    <div
                      class='modal-body'
                      style={{
                        padding: "10px 30px",
                        paddingTop: "20px",
                        textAlign: "left",
                      }}
                    >
                      Agreement Overview This Agreement is between Marching
                      Frogs ("WE") and YOU. By using our services, you
                      acknowledge that you have read, understood, and agree to
                      be bound by the terms, and that you will comply with all
                      applicable laws and regulations. We encourage you to
                      review and comply with proprietary notices and copyright
                      information. Please note that there may be potential
                      errors in the information provided on the website. We
                      reserve the right to modify these terms without prior
                      notice. These terms and conditions are related to the
                      services provided by Marching Frogs. You are required to
                      review and understand these terms before utilizing our
                      services. By using our services, you acknowledge complete
                      understanding and acceptance of these terms and
                      conditions. We disclaim any reliance on statements outside
                      of what is expressly detailed in this Agreement. Linking
                      to Other Websites We do not make any representations
                      regarding other websites accessed through our website.
                      These websites are independent of us, and we have no
                      control over their contents, images, or any other
                      material. We are not liable for any information or
                      services provided by the owner of the linked website. Our
                      endorsement or responsibility for the linked website is
                      not implied. We do not assume liability for any losses,
                      damages, or claims arising from harmful elements on the
                      linked website. Visitors and users should exercise caution
                      when using the linked website. We disclaim any and all
                      liability for the contents, images, or materials on the
                      linked website. Copyrights Materials provided on this
                      website are protected by copyright. Unauthorized copying,
                      reproduction, distribution, republication, downloading,
                      display, posting, or transmission of materials is strictly
                      prohibited without written permission. Visitors and users
                      are permitted to download one copy of materials for
                      personal or internal business use only. We reserve the
                      right to revoke permission to download materials in case
                      of breach of terms. Replicating or mirroring any material
                      without express written consent is prohibited. Content
                      shared by the organization is subject to exclusive
                      copyright protection. Unauthorized use of materials may
                      infringe upon copyright, trademark, privacy, and
                      communication laws. All content and functionality on the
                      website are the exclusive property of the organization or
                      its licensors and are protected by Indian copyright law.
                      Trademarks Trademarks utilized and exhibited on websites
                      are both registered and unregistered trademarks of
                      Marching Frogs. Other trademarks not solely owned by us
                      might be the property of others. No license or privilege
                      is granted to use any trademark or intellectual property
                      on these websites. We vigorously protect our intellectual
                      property rights. Usage of "Marching Frogs" and other
                      trademarks is strictly prohibited without written consent.
                      Integration of trademarks as part of a link requires
                      pre-approval in written form. Use of Website Content User
                      Postings Users acknowledge and agree that the website
                      shall own and have the unrestricted right to use, publish,
                      distribute, and exploit any information that users post on
                      the website. Users waive any and all claims against the
                      website for alleged or actual infringements of privacy
                      rights, publicity rights, moral rights, rights of
                      attribution, or any other intellectual property rights in
                      connection with the use and publication of such
                      submissions posted on the website. Users grant the website
                      a perpetual, royalty-free, non-exclusive, sub-licensable,
                      unrestricted, unlimited, worldwide, and irrevocable right
                      and license to use, reproduce, modify, publish, translate,
                      prepare derivative works, distribute, perform, or display
                      such submissions. The website does not represent or
                      endorse and is not responsible for the accuracy or
                      reliability of any submissions displayed, uploaded, or
                      posted on any message board or otherwise distributed
                      through the website. The website expressly disclaims any
                      and all liability related to submissions, and any reliance
                      upon such submissions by visitors shall be at their sole
                      discretion, liability, and risk.
                    </div>
                    <div class='modal-footer'>
                      <button
                        type='button'
                        class='btn btn-secondary'
                        data-dismiss='modal'
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col py-sm-3 py-lg-0'>
            <div
              className='single-footer-widget '
              style={{ textAlign: "center" }}
            >
              <h3>Our Policy</h3>

              <p
                type='button'
                data-toggle='modal'
                data-target='#exampleModalLong'
              ></p>
              <p
                type='button'
                data-toggle='modal'
                data-target='#ampleModalLong'
              >
                Privacy Policy
              </p>
              <p
                type='button'
                data-toggle='modal'
                data-target='#termsConditions'
              >
                Terms & Conditions
              </p>
            </div>
          </div>
        </Box>
        <div className='copyright-area' style={{ display: "flex" }}>
          <p>Copyright © 2024 Marching Frogs Events. All rights reserved.</p>
          <p className='ml-5'>
            <b> </b>
          </p>
        </div>
      </Box>
    </footer>
  );
};

export default OtherFooter;
