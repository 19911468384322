import React from "react";

const Attendees = ({ attending }) => {
  return (
    <div style={{ padding: "4% 0px" }}>
  
    </div>
  );
};

export default Attendees;
