import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LaxDiv from "../Shared/LaxDiv";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

function Our_events() {
  return (
    <div style={{ backgroundColor: "#F6F4F7", padding: "20px 30px" }}>
      <div className='container'>
        <div className='section-title'>
          <span>Listen to our Event Success Stories</span>
          <h2>Our Events</h2>
          <div className='bar'></div>
          {/* <LaxDiv text="Speakers" dataPreset="driftRight" /> */}
        </div>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          gap: "10px",
        }}
      >
        <Link to='/events-Amsterdam-legal'>
          <div style={{ maxHeight: "500px", borderRadius: "20px" }}>
            <div
              style={{
                background: "black",
                borderRadius: "20px 20px 0px 0px",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ padding: "5px 15px", fontSize: "17px" }}>
                GLA IIⁿᵈ Annual Meet 2023 - Amsterdam
              </span>
              <span
                style={{
                  background: "#E90F1C",
                  padding: "5px 15px",
                  borderRadius: "0px 20px 0px 0px",
                  fontSize: "12px",
                }}
              >
                {" "}
                Register Now
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src='/images/park_plaza.avif'
                alt=''
                style={{ width: "100%", height: "300px" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "black",
                  width: "100%",
                  color: "white",
                  padding: "10px 3px",
                  fontSize: "16px",
                }}
              >
                <span style={{ fontSize: "14px" }}>
                  Meet the Experts in Legal domain. Get the best in quality
                  knowledge sharing at GLA Amsterdam
                </span>
              </div>
            </div>
          </div>
        </Link>
        <Link to='/events-Amsterdam-ip'>
          <div style={{ maxHeight: "500px", borderRadius: "20px" }}>
            <div
              style={{
                background: "black",
                borderRadius: "20px 20px 0px 0px",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ padding: "5px 15px", fontSize: "17px" }}>
                GLA IIIʳᵈ Conference-IP 2023 - Amsterdam
              </span>
              <span
                style={{
                  background: "#E90F1C",
                  padding: "5px 15px",
                  borderRadius: "0px 20px 0px 0px",
                  fontSize: "12px",
                }}
              >
                {" "}
                Register Now
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src='/images/Amsterdam_event3.avif'
                alt=''
                style={{ width: "100%", height: "300px" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "black",
                  width: "100%",
                  color: "white",
                  padding: "10px 3px",
                  fontSize: "17px",
                }}
              >
                <span style={{ fontSize: "14px" }}>
                  Meet the Experts in IP domain. Get the best in quality
                  knowledge sharing at GLA Amsterdam
                </span>
              </div>
            </div>
          </div>
        </Link>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          gap: "10px",
          marginTop: "3%",
        }}
      >
        <Box
          sx={{
            maxHeight: "500px",
            borderRadius: "20px",
            background: "green",
            width: {
              xs: "100%",
              lg: "48%",
            },
          }}
        >
          <Link to='/events-Amsterdam-legal' style={{ width: "100%" }}>
            <div
              style={{
                background: "black",
                borderRadius: "20px 20px 0px 0px",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ padding: "5px 15px", fontSize: "17px" }}>
                GLA Ⅳᵗʰ Annual Meet 2024 - Amsterdam
              </span>
              <span
                style={{
                  background: "#E90F1C",
                  padding: "5px 15px",
                  borderRadius: "0px 20px 0px 0px",
                  fontSize: "12px",
                }}
              >
                {" "}
                Register Now
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src='/images/home-events-1.jpg'
                alt=''
                style={{ width: "100%", height: "300px" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "black",
                  width: "100%",
                  color: "white",
                  padding: "10px 3px",
                  fontSize: "16px",
                }}
              >
                <span style={{ fontSize: "14px" }}>
                  Meet the Experts in Legal domain. Get the best in quality
                  knowledge sharing at GLA Amsterdam
                </span>
              </div>
            </div>
          </Link>
        </Box>
        <Box
          sx={{
            maxHeight: "500px",
            borderRadius: "20px",
            width: {
              xs: "100%",
              lg: "52%",
            },
          }}
        >
          <Link to='/events-Amsterdam-ip' style={{ width: "100%" }}>
            <div
              style={{
                background: "black",
                borderRadius: "20px 20px 0px 0px",
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ padding: "5px 15px", fontSize: "17px" }}>
                {" "}
                GLA Vᵗʰ Conference-IP 2024 - Amsterdam
              </span>
              <span
                style={{
                  background: "#E90F1C",
                  padding: "5px 15px",
                  borderRadius: "0px 20px 0px 0px",
                  fontSize: "12px",
                }}
              >
                {" "}
                Register Now
              </span>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src='/images/Amsterdam.jpg'
                alt=''
                style={{ width: "100%", height: "300px" }}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  background: "black",
                  width: "100%",
                  color: "white",
                  padding: "10px 3px",
                  fontSize: "17px",
                }}
              >
                <span style={{ fontSize: "14px" }}>
                  Meet the Experts in IP domain. Get the best in quality
                  knowledge sharing at GLA Amsterdam
                </span>
              </div>
            </div>
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default Our_events;
