import React from 'react'
import LaxDiv from "../Shared/LaxDiv";


const ExhibitionLogo = () => {
  return (
    <div className="pb-20 " style={{marginBottom:"5%"}}> 
  
  
  </div>
  )
}

export default ExhibitionLogo